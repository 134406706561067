@import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: 'Jura', sans-serif;
  /* color: cyan; */
  color: #40e0d0;
  background-color: rgb(0,0,0);
}

header {
  display: flex;
  justify-content: center;
  font-size: 4rem;
  margin: 0 auto;
  padding-top: 3rem;
}

#drum-machine {
  height: 100vh;
  width: 100vw;
}

.container {
  background-color: rgb(20,20,50);
  display: grid;
  justify-items: center;
  max-width: 270px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  /* grid-auto-rows: 100px; */
  margin: 64px auto;
}

.drum-pad {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: rgb(50,50,50);
  margin: 5px;
}

#display {
  /* grid-column-start: 1;
  grid-column-end: 4; */
  grid-column: 1/4;
  height: 80px;
  display: flex;
  align-items: center;
  margin: 5px;
}